<template>
  <div class="home">
    <!-- 加载中 -->
    <div class="cover-wrap" v-show="loading_switch"></div>
    <div class="loading-box" v-show="loading_switch">
      <van-loading vertical class="loading" color="#fff">系统加载中，请稍后...</van-loading>
    </div>

    <div class="header-wrap">
      <div class="head-tip">我们将7x24小时竭诚为您解决所有的退款问题和疑问，请您务必放心！退款后将会自动关闭再次扣款，为避免退款失败，申请退款后无须其他操作。</div>
    </div>

    <div class="content-wrap">
      <div class="fun-box">
        <div class="fun-item" @click="handleRefundOnline()">
          <img src="https://bcloud.aitici.com/aipaintingdouying/image/20230726/7f7ed44829b2c7fd846a3c1745fb1e6d.png" alt="fun2" />
        </div>

        <div class="fun-item" @click="handleGoChat()">
          <img src="https://bcloud.aitici.com/aipaintingdouying/image/20230802/0c323db3b07b197be362c42f148bedfb.png" alt="fun3" />
        </div>
      </div>

      <div class="q-box">
        <div class="q-title fir">被扣款了，想了解被扣款的原因是什么？</div>
        <div class="q-content">本APP的自动续费扣款是因为您之前有开通了本产品的试用，同时授权同意了自动续费协议，试用期间可随时取消，试用到期后会按照自动续费协议进行续费，所以才产生这笔续费会员的扣款。</div>
        <div class="q-title">退款成功后，还会不会再被扣款？</div>
        <div class="q-content">您提交退款后，自动续费服务将自动关闭，不会再继续扣费。</div>
      </div>
    </div>

    <div class="footer-wrap">
      <div class="btn-box">
        <a class="btn-l" href="tel:4001161551" @click="eventTracking('5')">
          <img src="https://bcloud.aitici.com/aipaintingdouying/image/20230802/1ac9b8a30ca41c3ff6d0ea996912b243.png" alt="">
        </a>
        <div class="btn-r" @click="handleGoComplaint()">
          <img src="https://bcloud.aitici.com/aipaintingdouying/image/20230802/918377dc09da66c8f89cf3e38956f687.png" alt="">
        </div>
      </div>

      <div class="phone-tip">客服热线: 400-116-1551</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      loading_switch: false,
      timer: null,
    };
  },

  created() {},

  destroyed() {
    clearTimeout(this.timer);
  },

  methods: {
    //提交投诉
    handleGoComplaint() {
      let place = "3";
      this.eventTracking(place);
      this.$router.push("./oldcomplaint");
    },

    //在线自助退款
    handleRefundOnline() {
      let place = "1";
      this.eventTracking(place);
      sessionStorage.removeItem("statue");
      sessionStorage.removeItem("btnStatue");
      sessionStorage.removeItem("orderList");
      sessionStorage.removeItem("report_list");

      this.$router.push({ name: "online" });
    },

    //在线客服
    async handleGoChat() {
      let place = "6";
      this.eventTracking(place);
      this.loading_switch = true;
      let info = { uid: "", data: [], gid: "482707004" };
      let cond = `url=http://test776.aitici.com/index.php/index/Sevenfish/sevenfishList&is_post=0`;
      let result = await this.$axios
        .post(
          `https://complaint-service.aitici.com/index/index/zhuanfa`,
          cond,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .catch(function (error) {
          console.log(error);
        });
      // console.log('客户分组', result.data.data);
      if (result.data) {
        if (result.data.data.code == 200) {
          result.data.data.data.forEach((item, index) => {
            if (item.type == 3) {
              info.gid = item.h5_id;
            }
          });
        }
      }

      info.data = [{ key: "real_name", value: "支付宝" }];

      await this.fishkefu.Config(info);
      let qysdk = await this.fishkefu.openSdk();
      if (qysdk == "sdk尚未加载成功，请稍后再试") {
        this.timer = null;
        this.timer = setTimeout(async () => {
          await this.fishkefu.Config(info);
          let qysdk1 = await this.fishkefu.openSdk();
          this.loading_switch = false;
          if (qysdk1 == "sdk尚未加载成功，请稍后再试") {
            alert("sdk尚未加载成功，请稍后再试");
          }
        }, 3000);
      } else {
        this.loading_switch = false;
      }
    },

    //数据埋点
    async eventTracking(place) {
      let res = await this.$axios.post(
        "https://middlegrounpcomplaint.juzan.info/api/access_log/add",
        {
          place,
        }
      );
      // console.log('查看埋点数据', res);
      if (place == "2" || place == "5") {
        let taskId = localStorage.getItem("taskId");
        this.reportEvent(taskId);
      }
    },

    //埋点数据上报
    async reportEvent(c_no) {
      let res = await this.$axios.post(
        "https://middlegrounpcomplaint.juzan.info/api/event/index",
        {
          event: "cs_click",
          attr: "8",
          c_no: c_no,
          source: 1,
        }
      );
    },
  },
};
</script>

<style lang="less">
@import url("./home.less");
</style>
